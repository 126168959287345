import NavItem from './NavItem';
import NodesSubNav from './NodesSubNav';
import React, { useEffect } from 'react';
import { ButtonVariant } from '@jameswilliamknight/starbridge-ui';
import { useLocation } from '@tanstack/react-router';

interface RootNavProps {
    isVertical?: boolean;
}

const RootNav = ({ isVertical = false }: RootNavProps) => {
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        console.log('🍇 isUserRoute', isUserRoute, location.pathname);
    }, [location]);

    // Main navigation items
    const mainNavItems = [
        { to: '/', buttonText: 'Home' },
        { to: '/inbox', buttonText: 'Inbox', className: '' },
        { to: '/aspects', buttonText: 'Aspects' },
        { to: '/nodes', buttonText: 'Nodes' },
        { to: '/user', buttonText: 'User' },
    ];

    // Sub-navigation items for nodes
    const nodesSubNavItems = [
        { to: '/nodes/table', buttonText: 'Table' },
        { to: '/nodes/graph', buttonText: 'Graph' },
    ];

    // Sub-navigation items for user
    const userSubNavItems = [{ to: '/user/settings', buttonText: 'Settings' }];

    // Determine variant for main navigation items
    const getVariant = (to: string): ButtonVariant => {
        if (to === '/') return currentPath === to ? 'secondary' : 'tertiary';
        if (currentPath === to) return 'secondary';
        return currentPath.startsWith(`${to}/`) ? 'primary' : 'tertiary';
    };

    // Determine which SubNav to display
    const isNodesRoute =
        currentPath === '/nodes' || currentPath.startsWith('/nodes/');
    const isUserRoute =
        currentPath === '/user' || currentPath.startsWith('/user/');

    return (
        <nav
            className={`flex ${isVertical ? 'flex-col space-y-2 items-start' : 'w-full space-x-4'}`}>
            {/* Main Navigation Items */}
            <div
                className={`flex ${isVertical ? 'flex-col space-y-2 items-start' : 'gap-2'}`}>
                {mainNavItems.map(({ to, buttonText, className }) => (
                    <NavItem
                        key={to}
                        to={to}
                        buttonText={buttonText}
                        className={className}
                        variant={getVariant(to)}
                    />
                ))}
            </div>

            {/* Conditionally render the appropriate SubNav */}
            {isNodesRoute && (
                <div
                    className={`w-full flex ${isVertical ? '' : 'items-center justify-center'}`}>
                    <NodesSubNav
                        isVertical={isVertical}
                        subNavItems={nodesSubNavItems}
                    />
                </div>
            )}
            {isUserRoute && (
                <div
                    className={`w-full flex ${isVertical ? '' : 'items-center justify-center'}`}>
                    <NodesSubNav
                        isVertical={isVertical}
                        subNavItems={userSubNavItems}
                    />
                </div>
            )}
        </nav>
    );
};

export default RootNav;
