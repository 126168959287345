import ConnectionIndicator from '../app/components/ConnectionIndicator';
import RootNav from '../app/components/nav/RootNav';
import useOnboardingStore from '../app/state/useOnboardingStore';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { SignedIn, SignedOut, useClerk } from '@clerk/clerk-react';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { useEffect, useRef, useState } from 'react';
import { useRouteContext, useRouterState } from '@tanstack/react-router';
import { useUser } from '@clerk/clerk-react';
import {
    Button,
    DarkLightToggle,
    Text,
    TextStyle,
    TextType,
} from '@jameswilliamknight/starbridge-ui';
import {
    createRootRoute,
    Link,
    Outlet,
    useLayoutEffect,
    useRouter,
} from '@tanstack/react-router';
import ErrorPage from '$pages/ErrorPage';
// Import your ErrorPage
const isDevelopment = import.meta.env.DEV;

/*
 * __root.tsx
 */
const RootComponent = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const { isSignedIn, user, isLoaded } = useUser();
    const router = useRouter();
    const isOnboarding = useOnboardingStore((state) => state.isOnboarding);

    useLayoutEffect(() => {
        document.body.classList.add(
            'dark:bg-neutral-800',
            'bg-neutral-300',
            'transition',
            'duration-300',
        );
    }, []);

    const { signOut } = useClerk();

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node)
            ) {
                setIsMenuOpen(false);
            }
        }

        function handleResize() {
            if (window.innerWidth >= 1024) {
                setIsMenuOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const routerState = useRouterState();
    const path = routerState.location.pathname;

    return (
        <div className="w-screen h-screen flex flex-col bg-neutral-300 dark:bg-neutral-900 overflow-hidden box-border">
            <header className="p-4 px-5">
                <nav className="flex items-center justify-between px-3 py-2 dark:bg-neutral-800 bg-neutral-200 shadow rounded-xl">
                    {/* Sidebar */}
                    <button
                        className="block lg:hidden"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <Bars3Icon className="h-6 w-6 text-neutral-600 dark:text-neutral-300" />
                    </button>

                    {/* Nav */}
                    <div className="lg:flex lg:flex-row lg:items-center lg:justify-between hidden w-full">
                        <RootNav />
                    </div>

                    {/* Far items */}
                    <div className="mr-4 flex space-x-4 items-center ">
                        <Text
                            textStyle={TextStyle.Tertiary}
                            textType={TextType.Code}
                            className="text-nowrap bg-neutral-100 dark:bg-neutral-700 px-2 py-0.5 rounded text-xs">
                            {`path: `}
                            {path}
                        </Text>
                        <Text
                            textStyle={TextStyle.Tertiary}
                            textType={TextType.Small}
                            className="text-nowrap">
                            {user?.fullName}
                        </Text>
                        <ConnectionIndicator />
                        <div className="flex items-center justify-center">
                            <SignedIn>
                                <Button
                                    variant="tertiary"
                                    buttonText="Log Out"
                                    density="compact"
                                    onClick={() =>
                                        signOut(() =>
                                            router.navigate({ to: '/' }),
                                        )
                                    }
                                />
                            </SignedIn>
                            <SignedOut>
                                <Button
                                    variant="primary"
                                    density="compact"
                                    buttonText="Sign In"
                                    onClick={() =>
                                        router.navigate({ to: '/sign-in' })
                                    }
                                />
                            </SignedOut>
                        </div>
                        <DarkLightToggle />
                    </div>
                </nav>
                {isMenuOpen && (
                    <div
                        ref={menuRef}
                        className="lg:hidden fixed top-15 left-0 w-1/2 h-full bg-neutral-300 dark:bg-neutral-800/50 backdrop-blur-sm shadow-lg z-50 transition-all duration-1000 rounded-lg">
                        <div className="flex flex-col p-4 space-y-2">
                            <RootNav isVertical={true} />
                        </div>
                    </div>
                )}
            </header>
            <main className="flex-1 w-full h-full overflow-hidden rounded-b-lg m-0">
                <Outlet />
            </main>
            {isDevelopment && (
                <TanStackRouterDevtools position="bottom-right" />
            )}
        </div>
    );
};

export const Route = createRootRoute({
    component: RootComponent,
    errorComponent: ErrorPage,
    notFoundComponent: () => {
        return (
            <div>
                <p>This is the notFoundComponent configured on root route</p>
                <Link to="/">Start Over</Link>
            </div>
        );
    },
});
