import { Button, ButtonVariant } from '@jameswilliamknight/starbridge-ui';
import { Link } from '@tanstack/react-router';

interface NavItemProps {
    to: string;
    buttonText: string;
    variant?: ButtonVariant;
    className?: string;
}

const NavItem = ({
    to,
    buttonText,
    variant = 'tertiary',
    className = '',
}: NavItemProps) => {
    return (
        <Link
            to={to}
            className={className}
            activeProps={{
                className: '',
                'aria-current': 'page',
            }}
            activeOptions={{ exact: true }}>
            <Button
                density="compact"
                variant={variant}
                buttonText={buttonText}
            />
        </Link>
    );
};

export default NavItem;
