import useHealthProbe from '../data/useHealthProbe';
import { CheckCircleIcon, SignalSlashIcon } from '@heroicons/react/24/solid';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@jameswilliamknight/starbridge-ui';

const ConnectionIndicator = () => {
    const { isReady } = useHealthProbe();

    const text = isReady ? 'Connected' : 'Connecting';
    const textColour = isReady
        ? 'dark:text-neutral-700 text-neutral-400'
        : 'text-red-500 animate-pulse';

    const isConnected = isReady;

    return (
        <TooltipProvider>
            <div className="select-none font-mono flex items-center">
                {isConnected ? (
                    <Tooltip>
                        <TooltipTrigger>
                            <CheckCircleIcon className="dark:text-green-900 opacity-50 hover:opacity-100 text-neutral-600 w-6 h-6 cursor-default" />
                        </TooltipTrigger>
                        <TooltipContent
                            variant={'primary'}
                            density={'comfortable'}
                            side={'bottom'}
                            tooltipContent={`Connected`}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip>
                        <TooltipTrigger>
                            <SignalSlashIcon className="dark:text-red-800 opacity-50 hover:opacity-100 text-neutral-600 w-6 h-6 animate-pulse cursor-default" />
                        </TooltipTrigger>
                        <TooltipContent
                            variant={'primary'}
                            density={'comfortable'}
                            side={'bottom'}
                            tooltipContent={`Connecting`}
                        />
                    </Tooltip>
                )}
            </div>
        </TooltipProvider>
    );
};

export default ConnectionIndicator;
