import useOnboardingStore from '../state/useOnboardingStore';
import { Text } from '@jameswilliamknight/starbridge-ui';
import { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useUser } from '@clerk/clerk-react';

const AuthRedirectPage = () => {
    const navigate = useNavigate();
    const { user, isLoaded } = useUser();
    const setOnboarding = useOnboardingStore((state) => state.setOnboarding);

    useEffect(() => {
        if (isLoaded) {

            // perhaps we should check a bit more state around the user.
            // did this come from a sign-in, or sign-up? Should we have separate pages for that?
            // should we be doing this here, or in src/routes/authRedirect.tsx ???
            const isOnboarding = useOnboardingStore.getState().isOnboarding;

            if (isOnboarding) {
                navigate({ to: '/onboarding/step-1' });
            } else {
                navigate({ to: '/' });
            }
        }
    }, [user, isLoaded, navigate]);

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center space-y-8 p-8">
                <Text className="text-2xl">Redirecting...</Text>
                <Text className="text-sm text-neutral-500">Please wait</Text>
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-neutral-500 mx-auto mt-8" />
            </div>
        </div>
    );
};

export default AuthRedirectPage;
