import useOnboardingStore from '../../state/useOnboardingStore';
import { Text, TextType } from '@jameswilliamknight/starbridge-ui';
import { useNavigate } from '@tanstack/react-router';
import { useUser } from '@clerk/clerk-react';
// src/pages/Onboarding/OnboardingStep1.tsx

const OnboardingStep1 = () => {
    const { setOnboarding } = useOnboardingStore();
    const navigate = useNavigate();
    const { user } = useUser();

    const completeOnboarding = async () => {
        setOnboarding(false);
        await user!.update({
            unsafeMetadata: {
                isNewUser: false,
            },
        });
        navigate({ to: '/' });
    };

    return (
        <div className="flex flex-col gap-4">
            <Text textType={TextType.LargeHeading}>Welcome to Onboarding</Text>
            <Text>This is step 1 of the onboarding process.</Text>
            <button
                onClick={completeOnboarding}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                Finish Onboarding
            </button>
        </div>
    );
};

export default OnboardingStep1;
