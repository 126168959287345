import NavItem from './NavItem';
import React from 'react';
import { ButtonVariant } from '@jameswilliamknight/starbridge-ui';
import { useLocation } from '@tanstack/react-router';

// Define the props interface, including subNavItems
interface SubNavProps {
    isVertical?: boolean;
    subNavItems: { to: string; buttonText: string }[]; // Add subNavItems prop
}

const NodesSubNav = ({ isVertical = false, subNavItems }: SubNavProps) => {
    const location = useLocation();
    const currentPath = location.pathname;

    // Determine variant based on the current path
    const getVariant = (to: string): ButtonVariant => {
        return currentPath === to ? 'secondary' : 'tertiary';
    };

    return (
        <div
            className={`flex ${isVertical ? 'flex-col space-y-2 items-start' : 'gap-2 items-center'}`}>
            {subNavItems.map(({ to, buttonText }) => (
                <NavItem
                    key={to}
                    to={to}
                    buttonText={buttonText}
                    variant={getVariant(to)}
                />
            ))}
        </div>
    );
};

export default NodesSubNav;
