import useOnboardingStore from '../state/useOnboardingStore';
import { Text, TextStyle, TextType } from '@jameswilliamknight/starbridge-ui';
// Adjust path as necessary

const UserSettingsPage = () => {
    // Access `isOnboarding` and `setOnboarding` from the store
    const { isOnboarding, setOnboarding } = useOnboardingStore();

    // Handler for the checkbox toggle
    const handleToggle = () => {
        setOnboarding(!isOnboarding);
    };

    return (
        <div className="w-full h-full p-4">
            <Text
                textStyle={TextStyle.Primary}
                textType={TextType.LargeHeading}>
                User Settings Page
            </Text>
            <div className="mt-4">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={isOnboarding}
                        onChange={handleToggle}
                        className="checkbox-class" // Add any desired styling here
                    />
                    <span>Enable Onboarding</span>
                </label>
            </div>
        </div>
    );
};

export default UserSettingsPage;
