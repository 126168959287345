import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface OnboardingState {
    isOnboarding: boolean;
    setOnboarding: (value: boolean) => void;
}

const useOnboardingStore = create<OnboardingState>()(
    persist(
        (set) => ({
            isOnboarding: true,
            setOnboarding: (value) => set({ isOnboarding: value }),
        }),
        {
            name: 'onboarding-storage', // Storage key name
            storage: createJSONStorage(() => sessionStorage), // Use sessionStorage or switch to localStorage
        },
    ),
);

export default useOnboardingStore;
