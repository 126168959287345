import axios from 'axios';
import ReactDOM from 'react-dom/client';
import {
    ClerkProvider,
    SignedIn,
    SignedOut,
    SignIn
    } from '@clerk/clerk-react';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { dark } from '@clerk/themes';
import { routeTree } from './routeTree.gen';
import { StrictMode } from 'react';
import { SWRConfig } from 'swr';
import { Text } from '@jameswilliamknight/starbridge-ui';
import { wwjdBffUrlSecure, wwjdUrlProduction } from './app/constants';
import './main.css';
import SignInPage from '$pages/SignInPage';

// Disable SSR when running the dev server
export const ssr = false;

const router = createRouter({
    routeTree,
    // Other configuration options if necessary
});

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router;
    }
}

const isProduction = true;
axios.defaults.baseURL = isProduction ? wwjdUrlProduction : wwjdBffUrlSecure;

const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY as string;
if (!clerkPubKey) {
    throw new Error('Missing Publishable Key');
}

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    const clerkAppearance = {
        baseTheme: dark,
        elements: {
            card: 'bg-neutral-800',
            // Force dark mode for OAuth providers
            socialButtonsIconButton: 'dark:filter-invert',
            socialButtonsBlockButton: 'dark:filter-invert',
            formButtonPrimary: 'bg-neutral-700 text-neutral-100',

            footerActionLink: 'text-neutral-300',
            // Add other dark mode styles as needed
        },
        variables: {
            colorPrimary: '#000000',
            colorBackground: '#262626',
            colorText: '#ffffff',
            colorTextSecondary: '#a3a3a3',
        },
    };
    root.render(
        <div className="bg-transparent w-screen h-screen">
            <ClerkProvider
                publishableKey={clerkPubKey}
                appearance={clerkAppearance}
                signInUrl="/sign-in"
                afterSignUpUrl={"/authRedirect"} // TODO: separate flows.
                afterSignInUrl={"/authRedirect"}
                afterSignOutUrl={'/'}>
                <SWRConfig
                    value={{
                        refreshInterval: 3000,
                        fetcher: (resource, init) =>
                            fetch(resource, init).then((res) => res.json()),
                    }}>
                    <StrictMode>
                        <SignedIn>
                            <RouterProvider router={router} />
                        </SignedIn>
                        <SignedOut>
                            <div className="flex items-center justify-center h-screen">
                                <div className="text-center space-y-8 p-8">
                                    <Text className="text-2xl">
                                        Please sign in
                                    </Text>
                                    <div className="mt-4">
                                        <SignIn />
                                    </div>
                                </div>
                            </div>
                        </SignedOut>
                    </StrictMode>
                </SWRConfig>
            </ClerkProvider>
        </div>,
    );
}
